.swiper-pagination {
  bottom: -33px !important;
}
.swiper-pagination-tutorial {
  bottom: 123px !important;
}

.swiper-wrapper {
  will-change: transform;
}

.swiper-pagination-bullet-active {
  background-color: #ff0054 !important;
  box-shadow: 0px 0px 0px 3px rgba(255, 0, 84, 0.25);
  transition: box-shadow 150ms ease-out;
}

.gallery-navigation {
  --swiper-navigation-color: rgb(204, 204, 204);
}

.gallery-thumb .swiper-slide {
  opacity: 0.4;
}

.gallery-thumb .swiper-slide-thumb-active {
  opacity: 1;
}
