.label {
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: rgb(167, 167, 167);
}

.radio {
    display: none;
}
.radio:checked + .label {
    color: #FF005A;
    border-color: #FF005A;
}
