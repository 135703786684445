:root {
  --ion-background-color: #ffffff;
}

h1, h2, h3, h4, h5, h6, p,
button, button.uppercase, a, select, ul, span, label {
  text-transform: lowercase;
}

p[id*="-error"] {
  text-transform: none;
}

::-webkit-input-placeholder {
  text-transform: lowercase;
}
:-moz-placeholder {
  text-transform: lowercase;
}
::-moz-placeholder {
  text-transform: lowercase;
}
:-ms-input-placeholder {
  text-transform: lowercase;
}
::placeholder {
  text-transform: lowercase;
}

.safe-area-t {
  margin-top: env(safe-area-inset-top);
}

.safe-area-b {
  margin-bottom: env(safe-area-inset-bottom);
}

.swiper-pagination-bullet-active {
  background-color: #ff005a;
}

/* Reset */
body.bg-gray-200 ion-content {
  --ion-background-color: #121111;
}

ion-toggle {
  width: 40px;
  height: 20px;
  --handle-width: 10px;
  --handle-height: 10px;
  --handle-spacing: 5px;
  --handle-box-shadow: none;
  --background: #c4c4c4;
  --background-checked: #ff005a;
  --handle-background: #ffffff;
  --handle-background-checked: #ffffff;
}

.ql-container {
  margin-top: 0 !important;
}

.filter-blur {
  filter: blur(2px);
}